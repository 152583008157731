import * as React from 'react'
import '../styles/carrd.css'
import { StaticImage } from 'gatsby-plugin-image'
import { BrowserView, MobileView } from 'react-device-detect';
import { Link } from 'gatsby';

const IndexPage = () => {
    return (
        <div>
            <div id="wrapper">
                <div id="main">
                    <div className="inner">
                        <BrowserView>
                            <div id="container09" className="container default full screen">
                                <div className="wrapper">
                                    <div className="inner">
                                    </div>
                                </div>
                            </div>
                        </BrowserView>
                        <div id="container04" className="container columns">
                            <div className="wrapper">
                                <div className="inner" data-reorder="1,0">
                                    <div>
                                        <h1 id="text28" className="style5">Greens</h1>
                                        <p id="text03">Course GPS, leaderboards, handicap tracking, and more</p>
                                        <MobileView>
                                            <div id="image07" className="image" data-visibility="mobile">
                                                <a href="https://apps.apple.com/us/app/greens-golf/id1636114003?itsct=apps_box_link&itscg=30200" className="frame"><StaticImage src="../images/image07.png" objectFit="contain" alt="" /></a>
                                            </div>
                                        </MobileView>
                                    </div>
                                    <div>
                                        <BrowserView>
                                            <div id="image04" className="image">
                                                <span className="frame"><StaticImage src="../images/image04.png" alt="" objectFit="contain" /></span>
                                            </div>
                                            <p id="text01" className="style6"><strong>Scan to Download</strong></p>
                                        </BrowserView>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="container10" className="container default full screen">
                            <div className="wrapper">
                                <div className="inner">
                                    <div id="image05" className="image full">
                                        <span className="frame"><StaticImage src="../images/image05.png" alt="" objectFit="contain" /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="container01" className="style1 container columns">
                            <div className="wrapper">
                                <div className="inner" data-reorder="0,1">
                                    <div>
                                        <h3 id="text06" className="style2">15,000+ Courses</h3>
                                        <p id="text10" className="style1">Greens helps you plan your next shot by providing accurate distances to the pin and helping you locate all the hazards and sand traps. You can use the watch app too, so you never have to take your phone out of your pocket.</p>
                                    </div>
                                    <div>
                                        <div id="image01" className="image">
                                            <span className="frame deferred"><StaticImage src="../images/image01.png" alt="" objectFit="contain" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="container08" className="style1 container default">
                            <div className="wrapper">
                                <div className="inner">
                                    <hr id="divider03" className="style1 full screen" />
                                </div>
                            </div>
                        </div>
                        <div id="container02" className="style1 container columns">
                            <div className="wrapper">
                                <div className="inner" data-reorder="1,0">
                                    <div>
                                        <div id="image03" className="style1 image">
                                            <span className="frame"><StaticImage src="../images/image03.png" alt="" objectFit="contain" /></span>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 id="text12" className="style2">Leaderboards</h2>
                                        <p id="text13" className="style1">Greens will show you how you stack up against your friends. You can even turn on push notifications to see how your friends do after they finish a round.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="container06" className="style1 container default">
                            <div className="wrapper">
                                <div className="inner">
                                    <hr id="divider01" className="style1 full screen" />
                                </div>
                            </div>
                        </div>
                        <div id="container03" className="style1 container columns">
                            <div className="wrapper">
                                <div className="inner">
                                    <div>
                                        <h2 id="text22" className="style2">Handicaps + Stats</h2>
                                        <p id="text24" className="style1">Greens will automatically calculate your handicap as well as provide detailed stats about your last rounds. You'll always know what you need to practice at the driving range.</p>
                                    </div>
                                    <div>
                                        <div id="image06" className="style1 image">
                                            <span className="frame"><StaticImage src="../images/image06.png" alt="" objectFit="contain" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="container07" className="style1 container default">
                            <div className="wrapper">
                                <div className="inner">
                                    <hr id="divider02" className="style1 full screen" />
                                </div>
                            </div>
                        </div>
                        <div id="container13" className="style1 container columns">
                            <div className="wrapper">
                                <div className="inner" data-reorder="1,0">
                                    <div>
                                        <div id="image02" className="style1 image">
                                            <span className="frame"><StaticImage src="../images/image02.png" alt="" objectFit="contain" /></span>
                                        </div>
                                    </div>
                                    <div>
                                        <h2 id="text07" className="style2">Scorecards</h2>
                                        <p id="text02" className="style1">Greens generates detailed overviews of all you and your friends rounds. You can even share them to iMessage, Twitter, Instagram, and more.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="container15" className="container columns full screen">
                            <div className="wrapper">
                                <div className="inner">
                                    <div>
                                        <h2 id="text35">Get Started</h2>
                                        <div id="image08" className="image">
                                            <a href="https://apps.apple.com/us/app/greens-golf/id1636114003?itsct=apps_box_link&itscg=30200" className="frame"><StaticImage src="../images/image08.png" alt="" objectFit="contain" /></a>
                                        </div>
                                    </div>
                                    <span />
                                    <div>
                                        <ul id="links02" className="links">
                                            <li className="n01">
                                                <Link to="/terms">Term of Service</Link>
                                            </li>
                                        </ul>
                                        <ul id="links01" className="links">
                                            <li className="n01">
                                                <Link to="/privacy">Privacy Policy</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="container05" className="container default full screen">
                            <div className="wrapper">
                                <div className="inner">
                                    <ul id="icons01" className="icons">
                                        <li>
                                            <a className="n01" href="https://twitter.com/greensgolf" aria-label="Twitter">
                                                <svg><use xlinkHref="assets/icons.svg#twitter" /></svg>
                                                <span className="label">Twitter</span>
                                            </a>
                                        </li><li>
                                            <a className="n02" href="https://discord.gg/yg4Nwnaag3" aria-label="Discord">
                                                <svg><use xlinkHref="assets/icons.svg#discord" /></svg>
                                                <span className="label">Discord</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <p id="text23">© Bogie Studios LLC. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Head = () => (
    <>
        <title>Greens</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="description" content="The golf app for course GPS, leaderboards, handicaps, and more" />
        <meta property="og:site_name" content="Greens" />
        <meta property="og:title" content="Greens" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="The golf app for course GPS, leaderboards, handicaps, and more" />
        <meta name="twitter:card" content="&quot;summary_large_card&quot;" />
        <meta name="twitter:title" content="&quot;Greens&quot;" />
        <meta name="twitter:description" content="&quot;The golf app for course GPS, leaderboards, handicaps, and more&quot;" />
        <meta name="twitter:image" content="../images/share.jpg" />
        <meta name="twitter:creator" content="&quot;greensgolf&quot;" />
        <link href="https://fonts.googleapis.com/css2?display=swap&family=Inter:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Pacifico:ital,wght@0,400;1,400" rel="stylesheet" type="text/css" />
        <link rel="icon" type="image/png" href="../images/favicon.png" />
        <link rel="apple-touch-icon" href="../images/apple-touch-icon.png" />
        <link rel="stylesheet" href="assets/main.css" />
        <noscript>&lt;link rel="stylesheet" href="assets/noscript.css" /&gt;</noscript>
    </>
)

export default IndexPage